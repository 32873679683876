.customers-container {
    background-color: #F5F5F5;
    height: auto;
    position: relative;
    display: grid;
    align-items: center;
    padding-bottom: 5%;
    transition: 0.5s;
}

.customers-container .text-title-h2 {
    margin-top: 2%;
    margin-bottom: 2%;
}

.customers-img-container {
    height: 23vh;
    overflow: hidden;
    transition: 0.5s;
    margin-bottom: 5%;
}

.customers-img-container::-webkit-scrollbar {
    display: none;
}

.customers-img {
    padding: 2%;
}

.customers-img img {
    width: 100%;
}


@media (max-width: 999px) {
    .customers-container {
        height: auto;
        padding-bottom: 60px;
    }

    .customers-container .text-title-h2 {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .customers-img-container {
        margin-bottom: 2%;
        overflow: auto;
    }

    .customers-img {
        height: 200px;
        overflow: hidden;
    }

    .customers-img img {
        width: 100%;
    }

    .customers-container .btn-background-blue {
        background-color: transparent;
        border: 2px solid #265BDF;
        color: #265BDF;
    }

}