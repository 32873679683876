.home-container .overlay .text-title-h1 {
    margin-bottom: 5%;
}


.carousel-home-contenedor {
    position: relative;
}

.carousel-home {
    margin: auto;
    width: 100%;
    max-width: 100vw;
    position: relative;
    overflow: hidden;
}

.home-carousel {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    height: 100vh;
    width: 300%;
}

.home-contenedor {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.home-card-doc {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.home-card-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.home-card-box img {
    width: 100%;
    height: auto;
}

@supports (object-fit: cover) {
    .home-card-box img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}

.home-arrow-1,
.home-arrow-2 {
    position: absolute;
    top: 50%;
    font-size: 15px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.322);
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: 0.5s;
    z-index: 1;
}

.home-arrow-1 {
    left: 5vw;
}

.home-arrow-2 {
    right: 5vw;
}

.home-arrow-1:hover,
.home-arrow-2:hover {
    background-color: rgb(0, 0, 0);
}

@media (max-width: 999px) {
    .home-container .overlay {
        align-items: flex-start;
        padding-top: 120px;
    }
}