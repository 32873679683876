
.footer p {
  color: #FFFFFF33;
}

.byGirasoloLink a {
  text-decoration: none;
}

.by-girasolo {
  --background-default: transparent;
  --text-color-default: #5E5E5E;
  --star-color-default: #5E5E5E;
  --star-color-active: #f60606;
  --star-hole: #5E5E5E;
  --star-hole-inner: #20232C;
  --button-y: 0px;
  --star-y: 0px;
  --star-scale: 1;
  --star-rotate: 0deg;
  --star-hole-scale: 0;
  --text-x: 0px;
  --text-o: 1;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  outline: none;
  border: none;
  background: none;
  min-width: 125px;
  padding: 12px 24px 12px 0px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: var(--text-color-default);
  transform: translateY(var(--button-y)) translateZ(0);
  text-decoration: none;
  max-width: 218px;
  margin: auto;
}

.by-girasolo:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 9px;
  transition: transform 0.2s;
  transform: scale(var(--background-scale-x, 1), var(--background-scale-y, 1)) translateZ(0);
  background: var(--background-default);
}

.by-girasolo:active {
  --background-scale-x: .98;
  --background-scale-y: .96;
}

.by-girasolo span {
  display: block;
  opacity: var(--text-o);
  transform: translateX(var(--text-x));
  padding-left: 1px;
  padding-right: 1px;
}

.by-girasolo .icon {
  width: 36px;
  height: 119px;
  display: flex;
  pointer-events: none;
  position: relative;
  margin: -100px 2px 0 -8px;
}

.by-girasolo .icon:before {
  content: "";
  margin-top: auto;
  display: block;
  width: 36px;
  height: 12px;
  background: var(--star-hole);
  box-shadow: inset 0 3px 0 0 var(--star-hole-inner);
  border-radius: 100px/30px;
  transform: scale(var(--star-hole-scale));
  transform-origin: 100% 100%;
}

.by-girasolo .icon .heart {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 9px;
  bottom: -1px;
  transform: translateY(var(--star-y)) rotate(var(--star-rotate)) scale(var(--star-scale));
  border-radius: var(--star-radius, 0px);
  background: var(--star-color, var(--star-color-default));
  -webkit-clip-path: var(--star-clip, polygon(51% 87%, 21% 58%, 17% 52%, 16% 45%, 16% 38%, 18% 31%, 23% 26%, 28% 23%, 33% 20%, 40% 20%, 45% 22%, 49% 25%, 51% 28%, 55% 24%, 60% 21%, 65% 20%, 71% 20%, 76% 23%, 81% 27%, 85% 32%, 87% 37%, 88% 43%, 86% 49%, 83% 54%, 80% 59%));
  clip-path: var(--star-clip, polygon(51% 87%, 21% 58%, 17% 52%, 16% 45%, 16% 38%, 18% 31%, 23% 26%, 28% 23%, 33% 20%, 40% 20%, 45% 22%, 49% 25%, 51% 28%, 55% 24%, 60% 21%, 65% 20%, 71% 20%, 76% 23%, 81% 27%, 85% 32%, 87% 37%, 88% 43%, 86% 49%, 83% 54%, 80% 59%));
  transition: border-radius 0.2s, background 0.2s, -webkit-clip-path 0.2s;
  transition: clip-path 0.2s, border-radius 0.2s, background 0.2s;
  transition: clip-path 0.2s, border-radius 0.2s, background 0.2s, -webkit-clip-path 0.2s;
}

.by-girasolo.active .icon {
  opacity: 1;
}

.by-girasolo .icon .heart:before, .by-girasolo .icon .heart:after {
  content: "";
  position: absolute;
  transition: background 0.2s, box-shadow 0.2s;
}

.by-girasolo .icon .heart:before {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  left: 7px;
  top: 8px;
}

.by-girasolo .icon .heart:after {
  width: 4px;
  height: 2px;
  left: 8px;
  top: 11px;
  transition: border-radius 0.2s;
}

.by-girasolo.heart-round {
  --star-clip: polygon(51% 87%, 21% 58%, 17% 52%, 16% 45%, 16% 38%, 18% 31%, 23% 26%, 28% 23%, 33% 20%, 40% 20%, 45% 22%, 49% 25%, 51% 28%, 55% 24%, 60% 21%, 65% 20%, 71% 20%, 76% 23%, 81% 27%, 85% 32%, 87% 37%, 88% 43%, 86% 49%, 83% 54%, 80% 59%);
  --star-radius: 50%;
}

.by-girasolo.active {
  --star-color: var(--star-color-active);
}
