.carousel-info-container {
    height: auto;
    padding-bottom: 8%;
}

.carousel-info-container .info-text-flex {
    margin-bottom: 5%;
}

.carousel-info-container .info-text-flex .text-container,
.carousel-info-container .info-text-flex .coutn-img-carousel-container {
    width: 50%;
}

.coutn-img-carousel-container {
    text-align: right;
}

.img-slider {
    height: 300px;
}

.img-slider img {
    width: 100%;
    height: 100%;
}

@supports (object-fit: cover) {
    .img-slider img {
        height: 99%;
        object-fit: cover;
        object-position: center center;
    }
}

.react-multiple-carousel__arrow {
    background: #00000080;
}

@media (max-width:999px) {
    .carousel-info-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .carousel-info-container .text-title-h2 {
        text-align: left;
    }

    .carousel-info-container .coutn-img-carousel-container .text-title-h2 {
        text-align: center;
    }
}