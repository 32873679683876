footer {
    background-color: #1C1C1E;
    width: 100%;
    height: 340px;
    position: relative;
    display: grid;
    align-items: center;
    color: #fff;
}

.footer-img {
    position: absolute;
    width: 20%;
    bottom: 0;
    right: 12vw;
}

.footer-img img {
    width: 100%;
}

footer .text-title-h2 {
    margin-top: 5%;
    margin-bottom: 5%;
}

.footer-buttons {
    display: flex;
}

.footer-buttons a {
    margin-right: 3%;
}


@media (max-width: 999px) {
    footer {
        height: auto;
        align-items: flex-end;
        padding-top: 60px;
    }

    .footer-buttons a {
        margin-right: 0%;
    }

    .footer-buttons button:first-child {
        margin-right: 0%;
        margin-bottom: 5%;
    }

    .footer-img {
        position: relative;
        width: 100%;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 15%;
    }

    .footer-img img {
        width: 250px;
    }

}