.subnav-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    z-index: 1;
}

.subnav-option-container {
    width: 25%;
}

.subnav-nombre-service{
    font-size: 18px;
}

.subnav-option-container a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.subnav-option-container a span {
    font-family: "Icons";
    color: #FFFFFF;
    font-size: 18px;
    margin-right: 5px;
    transition: 0.5s;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subnav-option-container a span:hover {
    background-color: #fff;
    color: #265BDF;
}

@media (max-width: 999px) {
    .subnav-container {
        display: grid;
        bottom: 50px;
    }

    .subnav-option-container {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .subnav-option-container span {
        display: block;
    }

    .subnav-option-container a span {
        width: 20px;
        height: 20px;
    }

    .subnav-option-container a span:hover {
        background-color: transparent;
        color: #FFFFFF;
    }
}
